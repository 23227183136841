
    @import '../assets/scss/mixins/mixins.scss';

    .brand-banner {
        display: flex;
        justify-content: center;
        height: 100px;
        margin-top: 15px;
        border-radius: 5px;
        align-items: center;
        background: #000;
        background-repeat: no-repeat;
        background-position: center;
    }
    .brand-description {
        display: none;
    }

    section#popular-series {
        .btn-black {
            font-size: 14px;
            margin: 15px auto 0;
            padding: 7px 15px;
            width: 100%;
        }
        .btn-blue {
            padding: 10px 15px;
            @include media(lg) {
                padding: 12px 40px;
            }
        }
        .nav {
            flex-wrap: nowrap;
            overflow-x: scroll;
            justify-content: left;
            overflow-y: hidden;
        }
        .nav-tabs .nav-item {
            @include media(lg) {
                width: 100%;
            }
            .nav-link {
                @include media(lg) {
                    width: 100%;
                }
            }

            @include media(lg) {
                border-right: 0;
                &:last-child .nav-link.active {
                    border-top-right-radius: 0;
                    border-bottom-right-radius: 5px;
                }
            }
            &:first-child {
                @include media(lg) {
                    border-top-left-radius: 5px;
                    border-top-right-radius: 5px;
                }
                .nav-link {
                    @include media(lg) {
                        border-bottom-right-radius: unset;
                        border-top-right-radius: 5px;
                        border-bottom-left-radius: 0;
                    }
                }
            }
            &:last-child {
                border-top-right-radius: 5px;
                border-bottom-left-radius: 5px;
                @include media(lg) {
                    border-bottom-left-radius: 5px;
                    border-top-right-radius: 0;
                }
                .nav-link {
                    @include media(lg) {
                        border-bottom-left-radius: unset;
                        border-top-right-radius: 5px;
                        &.active {
                            border-top-right-radius: 0;
                            border-bottom-right-radius: 5px;
                        }
                    }
                }
            }
        }

        .nav-tabs .nav-item {
            &:last-child {

                .nav-link {
                    @include media(lg) {
                        border-bottom-left-radius: 5px;
                        border-top-right-radius: unset;
                    }
                }
            }
        }
        .tab-content {
            padding-top: 0;
        }
        .mproducts {
            position: relative;
            flex-wrap: wrap;
            height: 520px;
            overflow: hidden;
            .mp-item {
                text-align: center;
                width: 50%;
                min-width: unset;
                margin-bottom: 25px;
            }
            .btn-black {
                position: absolute;
                bottom: 5%;
                z-index: 99;
                @include media(tablet) {
                    left: 30%;
                }
            }
        }
    }
    .section-text {
        font-size: 24px;
        font-weight: 500;
        margin-bottom: 35px;
        margin-top: 20px;
    }

    .brand-description {
        @include media(tablet) {
            margin-left: 80px;
            display: block;
            line-height: 1.2;
            p {
                margin-bottom: 0;
                color: #fff;
            }
        }
        @include media(desktop-md) {
            margin-left: 100px;
        }
    }

    @include media(lg) {
        .brand-banner {
            margin-top: 0;
            padding: 20px;
            flex-direction: column;
        }
        section#popular-series {
            .nav {
                flex-wrap: wrap;
                overflow-x: unset;
                justify-content: unset;
                margin-left: 0;
            }
            .mproducts {
                .mp-item {
                    margin-bottom: 15px;
                    width: 33.33%;
                }
            }
        }
    }


    .brand-banner {
        @include media(lg) {
            padding: 50px;
            flex-direction: row;
        }
    }



    @include media(tablet) {
        section#popular-series {
            .mproducts {
                margin-top: 50px;
                .mp-item {
                    width: 25%;
                }
            }
        }
    }

    .brand-banner {
        height: 200px;
    }



